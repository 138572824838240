import { template as template_c4809a7c8e77445b9c00f2e2d389d9b6 } from "@ember/template-compiler";
const FKLabel = template_c4809a7c8e77445b9c00f2e2d389d9b6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
