import { template as template_4200f7db2c9f42faa8652a3a12498390 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4200f7db2c9f42faa8652a3a12498390(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
