import { template as template_d1bc120e1f014c0eab4f8c5a0b83bb42 } from "@ember/template-compiler";
const FKText = template_d1bc120e1f014c0eab4f8c5a0b83bb42(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
